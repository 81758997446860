import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const domain = window.location.href.includes('localhost') ? 'partyrevive.es' : window.location.href

export default new Vuex.Store({
  state: {
    domain: domain.includes('partyrevive.es') ? 'partyrevive.es' : domain.includes('theboss.es') ? 'theboss.es' : 'buvacnight.es',
    isMobile: window.innerWidth < 767,
    isPartyRevive: domain.includes('partyrevive.es'),
    isTheBoss: domain.includes('theboss.es'),
    isBuvac: domain.includes('buvacnight.es'),
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
