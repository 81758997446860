<template>
  <nav class="navbar navbar-expand-md flex-md-column fixed-top navbar-dark">
    <a class="navbar-brand align-self-center m-0 pb-3 position-md-absolute pb-md-0" href="/"><img :src="logoSrc"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" @click="toggleShowMobileMenu"> 
        <span class="navbar-toggler-icon"></span>
      </button>
    <div class="collapse navbar-collapse justify-content-md-center w-100" :class="{ 'show': showMobileMenu }" id="navbarNav">
      <ul class="navbar-nav text-center">
      <li class="nav-item" :class="{ 'active': routeMatches('entradas') || routeMatches('home') }">
        <a class="nav-link" @click="$router.push('/entradas')">ENTRADAS</a>
      </li>
      <li class="nav-item" :class="{ 'active': routeMatches('eventos') }">
        <a class="nav-link" @click="$router.push('/eventos')">EVENTOS</a>
      </li>
      <li class="nav-item" :class="{ 'active': routeMatches('listas') }">
        <a class="nav-link" @click="$router.push('/listas')">LISTAS</a>
      </li>
      <li class="nav-item" :class="{ 'active': routeMatches('fotos') }">
        <a class="nav-link" href="https://www.facebook.com/partyrevive.eventos/photos_by" target="_blank">FOTOS</a>
      </li>
      <li class="nav-item" :class="{ 'active': routeMatches('login') }" v-if="!token">
        <a class="nav-link" @click="$router.push('/login')">LOGIN</a>
      </li>
      <li class="nav-item" :class="{ 'active': routeMatches('perfil') }"  v-if="token">
        <a class="nav-link" @click="$router.push('/perfil')">MI PERFIL</a>
      </li>
      <li class="nav-item" :class="{ 'active': routeMatches('contacto') }">
        <a class="nav-link" @click="$router.push('/contacto')">CONTACTO</a>
      </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    const isMobile = this.$store.state.isMobile
    return {
      showMobileMenu: !isMobile
    }
  },
  computed: {
    token () {
      return this.$cookies.get('token')
    },
    logoSrc () {
      if (this.$store.state.isPartyRevive) {
        return require('../assets/logo1x1.png')
      } else if (this.$store.state.isBuvac) {
        return require('../assets/logo-buvac.png')
      } else if (this.$store.state.isTheBoss) {
        return require('../assets/logo-theboss.png')
      } else {
        return require('../assets/logo1x1.png')
      }
    }
  },
  methods: {
    routeMatches (route) {
      return this.$route && this.$route.name && this.$route.name.toLowerCase() === route
    },
    toggleShowMobileMenu () {
      if (this.$store.state.isMobile) {
        this.showMobileMenu = !this.showMobileMenu
      }
    }
  }

}
</script>

<style lang="scss">
a {
  cursor: pointer;
}
@import '../assets/scss/global.scss';
$nth-nav-item-that-needs-margin-right     : 3;
$width-of-navbar-brand-or-logo-in-px      : 160px;
$padding-x-for-navbar-brand-or-logo-in-px : 16px;

@mixin hoverNavItem {
  transition: .5s all ease-in;
  a {
    color: $red !important;
  }
  padding-bottom: 5px;
  border-bottom: 1px solid $red;
}

.active {
  @include hoverNavItem();
}

.align-self-center img {
  width: 30px;

  @include mobile {
    width: 40px;
  }
}

@media (min-width: 768px) {
	
	.position-md-absolute {
		position: absolute;
	}
	.navbar-nav .nav-item:nth-child(#{$nth-nav-item-that-needs-margin-right}) {
		margin-right: $width-of-navbar-brand-or-logo-in-px + ($padding-x-for-navbar-brand-or-logo-in-px * 2);
	}
}

.navbar-dark .navbar-nav .nav-link {
  :not(.active) {
    color: white !important;
  }
}

.nav-item {
  font-weight: bold;
  a {
    color: white;
    &:hover {
      transition: .5s all ease-in;
      color: $red !important;
    }
  }

  &:hover {
    @include hoverNavItem();
  }
}

@include mobile {
  .navbar {
    padding: 1rem 2rem;
  }

  .navbar-nav {
    background-color: #282828;
    padding: 1rem 0;
    margin: 0 0 0 50%;
    border-radius: 20px;
    border: 1px solid white;
  }

  .navbar-dark .navbar-toggler {
    border: 0px;
  }
}
</style>