import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueCookies)

// Set api domain to axios default host
axios.defaults.baseURL = window.location.href.includes('localhost') ? 'https://api.partyrevive.es' : 'https://api.partyrevive.es'
/* axios.defaults.baseURL = 'https://localhost:44388' */

axios.interceptors.request.use(config => {
  const token = Vue.$cookies.get('token');
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
