<template>
  <div id="app">
    <Navbar v-if="$store.state.isPartyRevive"></Navbar>
    <router-view class="container mb-5 router-view"/>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
/* import Footer from './components/Footer.vue' */
import Navbar from '@/components/Navbar.vue'

export default {
  components: {
    Navbar
  },
  metaInfo () {
    {/* <meta property="og:title" content="PARTY REVIVE" />
    <meta property="og:description" content="Eres tú y lo componemos todos" />
    <meta property="og:url" content="https://partyrevive.es/" />
    <meta property="og:site_name" content="PARTY REVIVE" />
    
    <meta property="og:image" content="https://partyrevive.es/img/pop-up-market-3-4-dic.73545141.jpg" />
    <meta property="og:image:secure_url" content="https://partyrevive.es/img/pop-up-market-3-4-dic.73545141.jpg" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="Party Revive" />
    
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:description" content="Eres tú y lo componemos todos" />
    <meta name="twitter:title" content="PARTY REVIVE" />
    <meta name="twitter:image" content="https://partyrevive.es/img/pop-up-market-3-4-dic.73545141.jpg" />
    
    <meta property="og:image" content="https://partyrevive.es/img/pop-up-market-3-4-dic.73545141.jpg" />
    <meta property="og:image:secure_url" content="https://partyrevive.es/img/pop-up-market-3-4-dic.73545141.jpg" />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="300" /> 
    <meta property="og:image:height" content="300" /> */}

  /*   <title>PARTY REVIVE</title>
    <link rel="icon" href="<%= BASE_URL %>logo1x1.png">
    <link rel="preconnect" href="https://fonts.googleapis.com"> */
    const title = this.$store.state.isPartyRevive ? 'PARTY REVIVE' : (this.$store.state.isBuvac ? 'BUVAC NIGHT' : 'THE BOSS')
    const description = this.$store.state.isPartyRevive ? 'Eres tú y lo componemos todos' : this.$store.state.isBuvac ? 'La noche en Buvac!' : 'The Boss'
    const url = this.$store.state.isPartyRevive ? 'https://partyrevive.es/' : this.$store.state.isBuvac ? 'https://buvacnight.es/' : 'https://theboss.es/'
    const image = this.$store.state.isPartyRevive ? 'https://i.ibb.co/p2vdmqg/logo1x1.png' : this.$store.state.isBuvac ? 'https://i.ibb.co/MCqSGyv/logo-buvac.png' : 'https://i.ibb.co/7rJ5VNd/theboss-logo-negro.png'
    const favicon = this.$store.state.isPartyRevive ? 'https://i.ibb.co/p2vdmqg/logo1x1.png' : this.$store.state.isBuvac ? 'https://i.ibb.co/MCqSGyv/logo-buvac.png' : 'https://i.ibb.co/7rJ5VNd/theboss-logo'

    return {
      title: title,
      meta: [
        { property: 'og-title', content: title },
        { property: 'og-description', content: description },
        { property: 'og-url', content: url },
        { property: 'og-site_name', content: title },
        { property: 'og-image', content: image },
        { property: 'og-image:secure_url', content: image },
        { property: 'og-image:width', content: '1200' },
        { property: 'og-image:height', content: '630' },
        { property: 'og-image:alt', content: title },
        { name: 'twitter-card', content: 'summary_large_image' },
        { name: 'twitter-description', content: description },
        { name: 'twitter-title', content: title },
        { name: 'twitter-image', content: image },
        { property: 'og-image', content: image },
        { property: 'og-image:secure_url', content: image },
        { property: 'og-image:type', content: 'image/png' },
        { property: 'og-image:width', content: '1200' },
        { property: 'og-image:height', content: '600' }
      ],
      link: [
        { rel: 'icon', href: favicon }
      ]
    }
  },
  mounted () {
    if (this.$store.state.isPartyRevive) {
      document.body.classList.add('pr-theme')
    } else if (this.$store.state.isBuvac) {
      document.body.classList.add('bn-theme')
    } else {
      document.body.classList.add('tb-theme')
    }
  }
  /* components: {
    Footer
  } */
}
</script>

<style lang="scss">
@import './assets/scss/global.scss';

body {
  margin: 0;
  color: white !important;
}

body {
  &.pr-theme {
    background-image: url('./assets/img/cover_primary.jpg');
    background-position: center;
    background-size: cover;
  }
  &.bn-theme {
    background-color: rgb(31, 31, 31);
    background-position: center;
    background-size: cover;
  }
  &.tb-theme {
    background-color: rgb(31, 31, 31);
    background-position: center;
    background-size: cover;
  }
  height: 100vh;
}

@include desktop {
  .router-view {
    padding-top: 5rem;
  }
} 

</style>
