import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Entradas.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/mike-morato',
    name: 'MIKE MORATO ISLA CRISTINA',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/entradas',
    name: 'Entradas',
    component: () => import('../views/Entradas.vue')
  },
  {
    path: '/entradas/:eventId',
    name: 'EntradasConId',
    component: () => import('../views/Entradas.vue')
  },
  {
    path: '/admin',
    name: 'AdminMenu',
    component: () => import('../views/admin/Menu')
  },
  {
    path: '/admin/qr',
    name: 'QrValidator',
    component: () => import('../views/admin/QRValidator')
  },
  {
    path: '/admin/list-entries',
    name: 'ListEntries',
    component: () => import('../views/admin/ListEntries')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/eventos',
    name: 'Eventos',
    component: () => import('../views/Parties')
  },
  {
    path: '/fotos/:party',
    name: 'Fotos',
    component: () => import('../views/PartyPhotos')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import('../views/Contact')
  },
  {
    path: '/account/activate',
    name: 'Activación de cuenta',
    component: () => import('../views/AccountActivation')
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import('../views/Profile')
  },
  {
    path: '/listas',
    name: 'Listas',
    component: () => import('../views/Lists')
  },
  {
    path: '/listas/:eventId',
    name: 'ListasConId',
    component: () => import('../views/Lists')
  },
  {
    path: '/comprar',
    name: 'Comprar',
    component: () => import('../views/PaymentGateway')
  },
	{
    path: '/gracias',
    name: 'Gracias',
    component: () => import('../views/Gracias')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
